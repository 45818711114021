import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Layout, Menu, Dropdown, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { adminRoutes } from '../../routes'
import './frame.css'
import logo from '../../../public/logo.png'
import Avatar from 'antd/lib/avatar/avatar';
import cookie from "react-cookies";

const routes = adminRoutes.filter(route => route.isShow)
const { Header, Content, Sider } = Layout;

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  //
  render() {
    //
    let ss = this.props.history.location.pathname.split("/")
    const items = routes.map((route, index) => {
      return {
        key: route.path,
        icon: route.icon,
        label: route.title,
        children: route.children?route.children.map((item, _) => {
          return {
            key: item.path,
            label: item.title,
          };
        }):'',
      };
    });
    //////////////////////////////
    const popMenu = (
      <Menu onClick={(p) => {
        if (p.key === 'logout') {
          cookie.remove("token")
          this.props.history.push('/login')
        } else {
          message.info(p.key)
        }
      }}>
        <Menu.Item key='logout'>退出登录</Menu.Item>
      </Menu>
    )
    return (
      <Layout style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }}>
        <Header className="header" style={{
          backgroundColor: '#001529'
        }}>
          <div style={{ color: '#fff', height: '100%', fontWeight: 'bold', fontSize: '20px' }}><img src={logo} alt='' style={{ height: '60%', marginRight: '10px' }} />花都形象艺术学校管理后台</div>
          <Dropdown overlay={popMenu}>
            <div>
              <Avatar style={{ margin: '0 5px' }}>admin</Avatar>
              <span style={{ color: 'white' }}>管理员</span>
              <DownOutlined style={{ color: 'white' }} />
            </div>
          </Dropdown>
        </Header>
        <Layout>
          <Sider width={200} className="site-layout-background" >
            <Menu
              mode="inline"
              defaultSelectedKeys={[this.props.history.location.pathname]}
              // defaultOpenKeys={["/"+ss[1]+"/"+ss[2]]}
              defaultOpenKeys={["/admin/home","/admin/admissions","/admin/schoolprofile","/admin/news"]}
              style={{ height: '100%', borderRight: 0 }}
              items={items}
              onClick={p => this.props.history.push(p.key)}
            >
              {/* {routes.map(route => {
                return (
                  <Menu.Item
                    onClick={p => this.props.history.push(p.key)}
                    key={route.path}
                  >
                    {route.icon}
                    {route.title}
                  </Menu.Item>
                );
              })} */}
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 16px 16px' }}>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb> */}
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                height: '100%',
              }}
            >
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}
export default withRouter(Index)
