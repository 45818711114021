import React, { Component } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Switch, Button, Table, Input, Spin, Drawer, Space, Modal, message, } from 'antd';
import service from "../../request"
import moment from "moment";
//
import EditorLink from "../homepage/links/editorLink"
import AddLink from "../homepage/links/AddLink"
const { Search } = Input;
export default class Link extends Component {
  constructor(props) {
    super(props)
    //
    this.childEditorRef = null
    this.childaddRef = null
    this.state = {
      Data: [],
      pages: 1,//当前页
      // page: 1,
      count: 0,
      offset: 0,
      searchValue: "",
      IsTop: 0,
      tableItem: {},//当前行数据
      tableID: 0,//当前行ID
      header: 'http://49.4.2.15:9090/',
      ListLoading: false,
      EditorLoading: false,// 加载状态
      EditorVisible: false,
      addLoading: false,// 加载状态
      addVisible: false,
      DeleteLoading: false,// 加载状态
      DeleteVisible: false,
    }
  }
  render() {
    const columns = [
      {
        title: '链接名称', dataIndex: 'Name', key: 'Name', render: (text, record, index) => {
          return <div >{record.Name ? (record.Name.length > 12 ? record.Name.substring(0, 12) + "..." : record.Name) : ""}</div>
        }
      },
      {title: '链接地址', dataIndex: 'URL', key: 'URL',},
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorShowDrawer(record)} style={{ color: "#2BC17B", }} >编辑</Button>
            <Button onClick={() => this.deleteShowModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];

    const paginationProps = {
      current: this.state.page, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.count, // 总条数
      onChange: page => handlePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handlePageChange = (page) => {
      // console.log(page, "page", paginationProps.pageSize)
      this.setState({ pages: page })
      this.paging(page)
    }
    return (
      <div style={{ minWidth: "1200px" }}>
        {/* 头部 */}
        <div style={{ background: "#f5f9fa", width: "100%", height: "60px", padding: "10px", display: "flex", justifyContent: 'flex-end', flexDirection: 'row', }}>
          {/* <Search placeholder="input search text" size="large" style={{ width: "300px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} enterButton /> */}
          {/* <Button type="primary" style={{ height: "40px", marginRight: "20px" }}>高级筛选</Button> */}
          <Button type="primary" style={{ height: "40px" }} onClick={() => this.addShowDrawer()}>添加</Button>

          <Spin spinning={this.state.addLoading} tip="加载中......" size="large">
            <Drawer
              visible={this.state.addVisible}
              title="添加"
              width='calc(100% - 200px)'
              keyboard={true}//Esc关闭
              onClose={() => this.onAddClose()}
              destroyOnClose={true}
            >
              <AddLink ref={this.addRef}  ></AddLink>
              {/* 底部 */}
              <div style={{ position: "absolute", left: "24px", bottom: "24px" }}> 
              <Space>
              <Button type="primary" onClick={() => this.addSave()} >确定</Button>
              <Button onClick={() => this.onAddClose()} >取消</Button>
              </Space> 
              </div> -
            </Drawer>
          </Spin>
        </div>
        {/* 内容 */}
        <div style={{ marginTop: "20px", width: "100%", height: "100%", background: "#fff" }}>
          <Spin spinning={this.state.ListLoading} tip="加载中......" size="large">
            <Table columns={columns}
              // rowSelection={rowSelection}
              dataSource={this.state.Data}
              className="table"
              size={"small"}
              // bordered={true}
              pagination={paginationProps}
            />
          </Spin>
          <Spin spinning={this.state.EditorLoading} tip="加载中......" size="large">
            <Drawer
              visible={this.state.EditorVisible}
              title="修改"
              width='calc(100% - 200px)'
              keyboard={true}//Esc关闭
              onClose={() => this.onEditorClose()}
              destroyOnClose={true}
            >
              <EditorLink ref={this.editorRef} tableItem={this.state.tableItem} ></EditorLink>
              {/* 底部 */}
              <div style={{ position: "absolute", left: "24px", bottom: "24px" }}>
                <Space>
                  <Button onClick={() => this.editorSave()} type="primary">确定</Button>
                  <Button onClick={() => this.onEditorClose()}>取消</Button>
                </Space>
              </div>
            </Drawer>
          </Spin>
          <Spin spinning={this.state.DeleteLoading} tip="加载中......" size="large">
            <Modal
              destroyOnClose={true}
              centered={true}
              title={null}
              footer={null}
              modalRender={(modal) => (
                modal
              )}
              // bodyStyle={{ padding: '0px' }}
              visible={this.state.DeleteVisible}
              onCancel={() => this.hideModal()}
              closable={true}
              width={400}
            >
              <div style={{ heighe: "205px", padding: "10px 20px" }}>
                <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                <div style={{ marginTop: "20px", color: "#666666" }}>确定删除词条数据？</div>
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Button type="primary" style={{ background: "#2BC17B", border: "1px solid #2BC17B", marginRight: "40px" }} onClick={() => this.determineDelete()}>确认</Button>
                  <Button style={{ border: "1px solid #2BC17B", color: "#2BC17B" }} onClick={() => this.hideModal()}>取消</Button>
                </div>
              </div>
            </Modal>
          </Spin>
        </div>
      </div>
    )
  }
  componentDidMount() {
    this.paging(1)
  }
  renderChecked(IsTop) {
    switch (IsTop) {
      case 1:
        return true;
      case 2:
        return false;
      default:
        return true
    }
  }
  paging(page) {
    this.setState({
      ListLoading: true
    })
    return new Promise(resolve => {
      let limit = 10
      let newoffset = (page - 1) * limit
      service.request({
        url: "/link",
        method: "get",
        params: {
          limit: 10,
          offset: newoffset,
          columnID: 1,
          name: this.state.Name
        }
      }).then(res => {
        if (res.code != 200) {
          message.error('数据加载失败，请重试');
          this.setState({
            ListLoading: false
          })
        } else {
          // console.log('列表数据请求成功', res.data);
          this.setState({ Data: res.data, offset: newoffset, count: res.count, page: page, ListLoading: false })
          resolve()
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    })
  }
 
  // 添加
  addRef = (ref) => {
    this.childAddRef = ref
  }
  addShowDrawer() {
    this.setState({
      addVisible: true,
    });
  };
  onAddClose() {
    this.setState({
      addVisible: false,
    });
  };
  addSave() {
    if (this.childAddRef.state.Name != "" && this.childAddRef.state.URL != "" ) {
      this.setState({
        addLoading: true,
      })
      service.request({
        url: "/link",
        method: "post",
        data: {
          Name:this.childAddRef.state.Name,
          URL:this.childAddRef.state.URL,
        }
      }).then(res => {
        if (res.code == 200) {
          this.paging(1)
          this.setState({
            addLoading: false,
          })
          this.onAddClose()
          message.success('创建成功');
        } else {
          this.setState({
            addLoading: false,
          })
          if (res.code == 452) {
            message.error('创建失败,名字重复');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error("不能为空")
    }
  }
  // 修改
  editorRef = (ref) => {
    this.childEditorRef = ref
  }
  editorShowDrawer(record) {
    this.setState({
      tableItem: record,
      EditorVisible: true,
    });
  };
  onEditorClose() {
    this.setState({
      EditorVisible: false,
    });
  };
  editorSave() {
    if (this.childEditorRef.state.Name != "" && this.childEditorRef.state.URL != "" ) {
      this.setState({
        EditorLoading: true,
      })
      service.request({
        url: "/link",
        method: "put",
        data: {
          ID:this.childEditorRef.state.ID,
          Name:this.childEditorRef.state.Name,
          URL:this.childEditorRef.state.URL,
        }
      }).then(res => {
        if (res.code == 200) {
          this.paging(1)
          this.setState({
            EditorLoading: false,
          })
          this.onEditorClose()
          message.success('创建成功');
        } else {
          this.setState({
            EditorLoading: false,
          })
          if (res.code == 452) {
            message.error('创建失败,名字重复');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error("不能为空")
    }
  }

  // 删除显示窗口
  deleteShowModal(record) {
    this.setState({
      DeleteVisible: true,
      tableID: record.ID,
    });
  };
  hideModal() {
    this.setState({
      DeleteVisible: false,
    });
  };
  determineDelete() {
    service.request({
      url: "/link",
      method: "delete",
      data: {
        ID: this.state.tableID
      }
    }).then(res => {
      console.log(res)
      if (res.code == 200) {
        this.hideModal()
        this.paging(1)
        message.success('删除成功');
      } else {
        message.error('删除失败');
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })


  }

}
