import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { adminRoutes, renderRoutesMain } from './routes'
import Frame from './components/Frame/Index'
import { isLogined } from './Utlis/auth'
import cookie from "react-cookies";

// import './App.css'

export default class App extends Component {
    render() {
        return this.checkLogin() ? (
            <Frame>
                {/* <Switch> */}
                {/* <Switch>
                    {adminRoutes.map(route => {
                        return <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            render={routeProps => {
                                return <route.component {...routeProps} />
                            }} />
                    })}
                </Switch> */}
                {renderRoutesMain(adminRoutes)}
                {/* </Switch> */}
            </Frame>
        ) : (<Redirect to="/login" />)
    }
    checkLogin() {
        var token = cookie.load("token")
        if (token) {
            return true
        }
        //
        return false
    }
}
