import React from 'react';
import { Input, Button, Upload,  Select,message } from 'antd';
import { UploadOutlined } from '@ant-design/icons'

import service from '../../../request';

const { TextArea } = Input;
const { Option } = Select;
class AddMajor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID: 0,
            file: {},
            Name: "",
            Target: "",
            Employment:"",
            departmentData: [],
            // formdata : new FormData,
        };
    }
    // 抽屉
    render() {
        const { editorState } = this.state
        return (
            <div>
                <div style={{ width: "500px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>专业名称：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "20px" }} value={this.state.Name} onChange={(e) => {
                        this.setState({Name:e.target.value, })
                    }} placeholder="专业名称" />
                </div>
                <div style={{ width: "600px", marginBottom: "20px",display:"flex" }}>
                    <div style={{ width:"100px",color: "#000000", fontSize: "16px",marginRight:"16px" }}>专业目标：</div>
                    <TextArea
                        value={this.state.Target}
                        onChange={(e) => { 
                            this.setState({ Target: e.target.value })}}
                        placeholder="请输入"
                        autoSize={{
                            minRows: 3,
                            // maxRows: 5,
                        }}
                    />
                </div>
                <div style={{ width: "600px", marginBottom: "20px",display:"flex" }}>
                    <div style={{ width:"100px",color: "#000000", fontSize: "16px",marginRight:"16px" }}>就业方向：</div>
                    <TextArea
                        value={this.state.Employment}
                        onChange={(e) => { 
                            this.setState({ Employment: e.target.value })}}
                        placeholder="请输入"
                        autoSize={{
                            minRows: 3,
                            // maxRows: 5,
                        }}
                    />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "20px" }}>专业封面：</label>
                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}>
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </div>
               
               
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "20px" }}>选择院系：</label>
                    <Select
                        showSearch
                        style={{ width: "300px", }}
                        placeholder="选择院系"
                        optionFilterProp="children"
                        defaultActiveFirstOption={true}
                        dropdownMatchSelectWidth={true}
                        // value={this.state.departmentName}
                        onChange={(e) => {
                            this.setState({
                                DepartmentID: this.state.departmentData[e].ID,
                            })
                        }}
                    >
                        {this.state.departmentData.map((item, index) => (
                            <Option key={item.ID} value={index} >
                                {item.Name}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>

        );
    }
    componentDidMount() {
        this.departmentPaging(1)
    }
    departmentPaging(page) {
        this.setState({
          ListLoading: true
        })
        return new Promise(resolve => {
          let limit = 10
          let newoffset = (page - 1) * limit
          service.request({
            url: "/department",
            method: "get",
            params: {
              limit: 10,
              offset: newoffset,
            }
          }).then(res => {
            if (res.code != 200) {
              message.error('数据加载失败，请重试');
            } else {
              this.setState({ departmentData: res.data,},)
              resolve()
            }
          }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
          })
        })
      }
    Upload(f) {
        this.setState({
            file: f.file
        })
    }
    beforeUpload() {
        return false
    }
}

export default AddMajor;