import React from 'react';
import { Input, message } from 'antd';
import service from '../../../request';
// import service from "./request"



class AddLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            URL:"",
        };
    }
    // 抽屉
    render() {
        return (
            <div>
                <div style={{ width: "400px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>链接名称：</label>
                    <Input style={{ height: "35px", width: "200px", padding: "5px", marginLeft: "20px" }} value={this.state.Name} onChange={(e) => {
                        this.setState({ Name: e.target.value })
                    }} placeholder="链接名称：" />
                </div>
                <div style={{ width: "400px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>链接地址：</label>
                    <Input style={{ height: "35px", width: "200px", padding: "5px", marginLeft: "20px" }} value={this.state.URL} onChange={(e) => {
                        this.setState({ URL: e.target.value })
                    }} placeholder="链接地址：" />
                </div>
               
            </div>

        );
    }
    componentDidMount() {
    }

}

export default AddLink;