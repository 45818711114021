import React from 'react';
import { Input, Button, Upload, DatePicker, ConfigProvider, Switch, Select, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import service from '../../../request';
// import service from "./request"
const { TextArea } = Input;
class AddDepartment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            Name: "",
            EnglishName: "",
            TextAreaValue:"",
            // formdata : new FormData,
        };
    }
    // 抽屉
    render() {
        return (
            <div>
                <div style={{ width: "500px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>院系名称：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "30px" }} value={this.state.Name} onChange={(e) => {
                        this.setState({ Name: e.target.value }, () => {
                        })
                    }} placeholder="院系名称" />
                </div>
                <div style={{ width: "500px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>英文名称：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "30px" }} value={this.state.EnglishName} onChange={(e) => {
                        this.setState({ EnglishName: e.target.value }, () => {
                        })
                    }} placeholder="英文名称" />
                </div>
               
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginLeft: "32px",marginRight: "30px" }}>封面：</label>
                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }}>
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </div>
                <div style={{ width: "600px", marginBottom: "20px",display:"flex" }}>
                    <div style={{ width:"100px",color: "#000000", fontSize: "16px",marginRight:"25px" }}>院系备注：</div>
                    <TextArea
                        value={this.state.TextAreaValue}
                        onChange={(e) => { this.setState({ TextAreaValue: e.target.value })}}
                        placeholder="请输入"
                        autoSize={{
                            minRows: 3,
                            // maxRows: 5,
                        }}
                    />
                </div>
            </div>

        );
    }
    componentDidMount() {
    }
    Upload(f) {
        this.setState({
            file: f.file
        })
    }
    beforeUpload() {
        return false
    }

}

export default AddDepartment;