import React, { Component } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Button, Upload, message, Spin, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import service from "../../request"

//

export default class HomePage extends Component {
  constructor(props) {
    super(props)
    //
    this.state = {
      Loading: false,
      ICP: "",
      Phone: "",
      ZipCode: "",
      Postcodes: {},
      formdata: new FormData,
      header: 'http://49.4.2.15:9090/',
      fileList:[],
    }
  }
  render() {

    return (
      <div style={{ minWidth: "1200px" }}>
        {/* 头部 */}
        <div style={{ background: "#f5f9fa", width: "100%", height: "60px", padding: "10px", display: "flex", justifyContent: 'flex-end', flexDirection: 'row', }}></div>
        {/* 内容 */}
        <Spin spinning={this.state.Loading} tip="加载中......" size="large">
          <div style={{ marginTop: "20px", width: "100%", height: "100%",padding: "20px", background: "#fff" }}>
            <div style={{ width: "500px", marginBottom: "20px" }}>
              <label style={{ color: "#000000", fontSize: "16px" ,marginLeft: "10px"}}>icp备案：</label>
              <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "42px" }} value={this.state.ICP} onChange={(e) => {
                this.state.formdata.set("ICP", e.target.value,)
                this.setState({ ICP: e.target.value, })
              }} placeholder="icp备案" />
            </div>
            <div style={{ width: "500px", marginBottom: "20px" }}>
              <label style={{ color: "#000000", fontSize: "16px" }}>联系电话：</label>
              <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "40px" }} value={this.state.Phone} onChange={(e) => {
                this.state.formdata.set("Phone", e.target.value,)
                this.setState({ Phone: e.target.value, })
              }} placeholder="联系电话" />
            </div>
            <div style={{ width: "500px", marginBottom: "20px" }}>
              <label style={{ color: "#000000", fontSize: "16px" }}>邮政编码：</label>
              <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "40px" }} value={this.state.ZipCode} onChange={(e) => {
                this.state.formdata.set("ZipCode", e.target.value,)
                this.setState({ ZipCode: e.target.value, })
              }} placeholder="邮政编码" />
            </div>
            <div style={{ marginBottom: "20px" }}>
              <label style={{ color: "#000000", fontSize: "16px", marginRight: "40px",marginLeft: "11px", }}>二维码 ：</label>
              <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}>
                <Button icon={<UploadOutlined />}>上传二维码 </Button>
              </Upload>
            </div>
            <div style={{textAlign:"center"}}>
             
                <Button style={{marginRight:"50px"}} onClick={() => this.editorSave()} type="primary">保存</Button>
                <Button style={{marginLeft:"50px"}} onClick={() => this.onEditorClose()}>取消</Button>
             
            </div>
          </div>
        </Spin>

      </div>
    )
  }
  componentDidMount() {
    this.paging(1)
  }
  Upload(f) {

    this.state.formdata.set("Postcodes", f.file,)
    this.setState({ file: f.file, fileList: f.fileList })
  }
  beforeUpload() {
    return false
  }
  paging(page) {
    this.setState({
      Loading: true
    })
    return new Promise(resolve => {
      let limit = 10
      let newoffset = (page - 1) * limit
      service.request({
        url: "/system",
        method: "get",
        params: {
        }
      }).then(res => {
        if (res.code != 200) {
          message.error('数据加载失败，请重试');
          this.setState({
            Loading: false
          })
        } else {
          this.setState({
            ID: res.data.ID,
            ICP: res.data.ICP,
            Phone: res.data.Phone,
            ZipCode: res.data.ZipCode,
            Postcodes: res.data.Postcodes,
            Loading: false,
          }, () => {
            var CodeImg = this.state.Postcodes.split("/")
            var Postcodeslength = CodeImg.length - 1
            var PostcodesItem = CodeImg[Postcodeslength]
            this.setState({
              CoverName: PostcodesItem,
              fileList: [{
                uid: '-1',
                name: PostcodesItem,
                status: 'done',
                url: this.state.header + this.state.Postcodes,
                thumbUrl: this.state.header + this.state.Postcodes,
              }]
            })
            this.state.formdata.set("ID", this.state.ID,)
          })
          resolve()
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error,)
      })
    })
  }
  // 修改
  editorSave() {
    if (this.state.ICP != "" && this.state.Phone != "" && this.state.ZipCode != "" && this.state.Postcodes !="" ) {
      this.setState({
        Loading: true
      })
      service.request({
        url: "/system",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: this.state.formdata,
      }).then(res => {
        if (res.code == 200) {
          this.paging(1)
          this.setState({
            Loading: false
          })
          message.success('修改成功');
        } else {
          this.setState({
            Loading: false
          })
          message.error('创建失败,名字重复');
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error("除封面,其他项不能为空")
    }
  }

}
