import React, { Component } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Switch, Button, Table, Input, Spin, Drawer, Space, Modal, message, } from 'antd';
import service from "../../request"
import moment from "moment";
//
import AddDepartment from "../schoolprofile/departments/addDepartment"
import EditorDepartment from "../schoolprofile/departments/editorDepartment"
import AddMajor from "../schoolprofile/professional/addMajor"
import EditorMajor from "../schoolprofile/professional/editorMajor"
const { Search } = Input;
export default class SchoolProfile extends Component {
  constructor(props) {
    super(props)
    //
    this.EditorDepartmentRef = null
    this.AddDepartmentRef = null
    this.EditorMajorRef = null
    this.AddMajorRef = null
    this.state = {
      departmentData: [{ID:1,Name:"张山"}],
      MajorData: [{ID:1,Name:"张山"}],
      DepartmentPages: 1,//当前页
      MajorPages: 1,//当前页
      DepartmenCount: 0,
      MajorCount: 0,
      DepartmentOffset: 0,
      MajorOffset: 0,
      searchValue: "",
      majorSearchValue:"",
      departmentID:0,
      majorID:0,
      DepartmentItem: {},//当前行数据
      MajorItem: {},//当前行
      header: 'http://49.4.2.15:9090/',
      ListLoading: false,
      EditorLoading: false,// 加载状态
      EditorDepartmentVisible: false,
      addLoading: false,// 加载状态
      AddDepartmentVisible: false,
      addMajorVisible: false,
      EditorMajorVisible: false,
      DeleteLoading: false,// 加载状态
      DeleteDepartmentVisible: false,
      DeleteMajorVisible: false,
    }
  }
  render() {
    const departmentColumns = [
      { title: '院系名称', dataIndex: 'Name', key: 'Name',render: (text, record, index) => {
        return <div >{record.Name ? (record.Name.length > 12 ? record.Name.substring(0, 12) + "..." : record.Name) : ""}</div>
      }},
       { title: '英文名称', dataIndex: 'EnglishName', key: 'EnglishName', },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorDepartmentDrawer(record)} style={{ color: "#2BC17B", }} >编辑</Button>
            <Button onClick={() => this.deleteDepartmentModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    const majorColumns = [
      { title: '专业名称', dataIndex: 'Name', key: 'Name',render: (text, record, index) => {
        return <div >{record.Name ? (record.Name.length > 12 ? record.Name.substring(0, 12) + "..." : record.Name) : ""}</div>
      }},
     
      { title: '就业', dataIndex: 'Employment', key: 'Employment', render: (text, record, index) => {
        return <div >{record.Employment ? (record.Employment.length > 12 ? record.Employment.substring(0, 12) + "..." : record.Employment) : ""}</div>
      }},
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorMajorDrawer(record)} style={{ color: "#2BC17B", }} >编辑</Button>
            <Button onClick={() => this.deleteMajorShow(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    // moment(parseInt(时间戳)).format("YYYY-MM-DD HH:mm:ss"); 

    // const rowSelection = {
    //     onChange(selectedRowKeys, selectedRows) {
    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    //     },
    //     onSelect(record, selected, selectedRows) {
    //         console.log(record, selected, selectedRows);
    //     },
    //     onSelectAll(selected, selectedRows, changeRows) {
    //         console.log(selected, selectedRows, changeRows);
    //     },
    // };
    const deparnmentpaginationProps = {
      current: this.state.DepartmentPages, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.DepartmenCount, // 总条数
      onChange: page => handleDeparnmentChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleDeparnmentChange = (page) => {
      // console.log(page, "page", paginationProps.pageSize)
      this.setState({ pages: page })
      this.departmentPaging(page)
    }
    const majorpaginationProps = {
      current: this.state.MajorPages, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.MajorCount, // 总条数
      onChange: page => handleMajorChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleMajorChange = (page) => {
      // console.log(page, "page", paginationProps.pageSize)
      this.setState({ pages: page })
      this.majorPaging(page)
    }
    return (
      <div style={{ minWidth: "1200px" }}>
        {/* 系 */}
        <div style={{marginright:"20px",display:"flex",justifyContent:'center',flexDirection:'row', marginTop: "20px",}}>
          <div style={{ width: "100%", height: "100%", background: "#fff" }}>
          <div style={{ background: "#f5f9fa", width: "100%", height: "60px", padding: "10px", display: "flex", justifyContent: 'flex-end', flexDirection: 'row', }}>
          <Button type="primary" style={{ height: "40px" }} onClick={() => this.addDepartmentDrawer()}>添加院系</Button>

          <Spin spinning={this.state.addLoading} tip="加载中......" size="large">
            <Drawer
              visible={this.state.AddDepartmentVisible}
              title="添加"
              width='calc(100% - 200px)'
              keyboard={true}//Esc关闭
              onClose={() => this.addDepartmentClose()}
              destroyOnClose={true}
            >
              <AddDepartment ref={this.addDepartmentRef}  ></AddDepartment>
              {/* 底部 */}
              <div style={{ position: "absolute", left: "24px", bottom: "24px" }}> 
              <Space>
              <Button type="primary" onClick={() => this.addDepartmentSave()} >确定</Button>
              <Button style={{marginLeft:"20px"}} onClick={() => this.addDepartmentClose()} >取消</Button>
               </Space> 
              </div> 
            </Drawer>
          </Spin>
        </div>
            <Spin spinning={this.state.ListLoading} tip="加载中......" size="large">
              <Table columns={departmentColumns}
                // rowSelection={rowSelection}
                onRow={record => {
                  return {
                    onClick: event => {this.major(record)}, // 点击行
                  };
                }}
                dataSource={this.state.departmentData}
                className="table"
                size={"small"}
                // bordered={true}
                pagination={deparnmentpaginationProps}
              />
            </Spin>
            <Spin spinning={this.state.EditorLoading} tip="加载中......" size="large">
              <Drawer
                visible={this.state.EditorDepartmentVisible}
                title="修改"
                width='calc(100% - 200px)'
                keyboard={true}//Esc关闭
                onClose={() => this.editorDepartmentClose()}
                destroyOnClose={true}
              >
                <EditorDepartment ref={this.editorDepartmentRef} tableItem={this.state.DepartmentItem} ></EditorDepartment>
                {/* 底部 */}
                <div style={{ position: "absolute", left: "24px", bottom: "24px" }}>
                  <Space>
                    <Button onClick={() => this.editorDepartmentSave()} type="primary">确定</Button>
                    <Button style={{marginLeft:"20px"}} onClick={() => this.editorDepartmentClose()}>取消</Button>
                  </Space>
                </div>
              </Drawer>
            </Spin>
            <Spin spinning={this.state.DeleteLoading} tip="加载中......" size="large">
              <Modal
                destroyOnClose={true}
                centered={true}
                title={null}
                footer={null}
                modalRender={(modal) => (
                  modal
                )}
                // bodyStyle={{ padding: '0px' }}
                visible={this.state.DeleteDepartmentVisible}
                onCancel={() => this.hideModal()}
                closable={true}
                width={400}
              >
                <div style={{ heighe: "205px", padding: "10px 20px" }}>
                  <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                  <div style={{ marginTop: "20px", color: "#666666" }}>确定删除词条数据？</div>
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button type="primary" style={{ background: "#2BC17B", border: "1px solid #2BC17B", marginRight: "40px" }} onClick={() => this.determineDelete()}>确认</Button>
                    <Button style={{ border: "1px solid #2BC17B", color: "#2BC17B" }} onClick={() => this.hideModal()}>取消</Button>
                  </div>
                </div>
              </Modal>
            </Spin>
          </div>

          {/* 专业 */}
          <div style={{marginLeft:"20px",width: "100%", height: "100%", background: "#fff" }}>
          <div style={{ background: "#f5f9fa", width: "100%", height: "60px", padding: "10px", display: "flex", justifyContent: 'flex-end', flexDirection: 'row', }}>
          <Button type="primary" style={{ height: "40px" }} onClick={() => this.addMajorDrawer()}>添加专业</Button>
          <Spin spinning={this.state.addLoading} tip="加载中......" size="large">
            <Drawer
              visible={this.state.addMajorVisible}
              title="添加"
              width='calc(100% - 200px)'
              keyboard={true}//Esc关闭
              onClose={() => this.addMajorClose()}
              destroyOnClose={true}
            >
              <AddMajor ref={this.addMajorRef}  ></AddMajor>
              {/* 底部 */}
              <div style={{ position: "absolute", left: "24px", bottom: "24px" }}>
               <Space>
              <Button type="primary" onClick={() => this.addMajorSave()} >确定</Button>
              <Button style={{marginLeft:"20px"}} onClick={() => this.addMajorClose()} >取消</Button>
              </Space> 
              </div>
            </Drawer>
          </Spin>
        </div>
            <Spin spinning={this.state.ListLoading} tip="加载中......" size="large">
              <Table columns={majorColumns}
                dataSource={this.state.MajorData}
                className="table"
                size={"small"}
                // bordered={true}
                pagination={majorpaginationProps}
              />
            </Spin>
            <Spin spinning={this.state.EditorLoading} tip="加载中......" size="large">
              <Drawer
                visible={this.state.EditorMajorVisible}
                title="修改"
                width='calc(100% - 200px)'
                keyboard={true}//Esc关闭
                onClose={() => this.editorMajorClose()}
                destroyOnClose={true}
              >
                <EditorMajor ref={this.editorMajorRef} departmentData={this.state.departmentData} tableItem={this.state.MajorItem} ></EditorMajor>
                {/* 底部 */}
                <div style={{ position: "absolute", left: "24px", bottom: "24px" }}>
                  <Space>
                    <Button onClick={() => this.editorMajorSave()} type="primary">确定</Button>
                    <Button style={{marginLeft:"20px"}} onClick={() => this.editorMajorClose()}>取消</Button>
                  </Space>
                </div>
              </Drawer>
            </Spin>
            <Spin spinning={this.state.DeleteLoading} tip="加载中......" size="large">
              <Modal
                destroyOnClose={true}
                centered={true}
                title={null}
                footer={null}
                modalRender={(modal) => (
                  modal
                )}
                // bodyStyle={{ padding: '0px' }}
                visible={this.state.DeleteMajorVisible}
                onCancel={() => this.deleteMajorClose()}
                closable={true}
                width={400}
              >
                <div style={{ heighe: "205px", padding: "10px 20px" }}>
                  <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                  <div style={{ marginTop: "20px", color: "#666666" }}>确定删除词条数据？</div>
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button type="primary" style={{ background: "#2BC17B", border: "1px solid #2BC17B", marginRight: "40px" }} onClick={() => this.DeleteMajor()}>确认</Button>
                    <Button style={{ border: "1px solid #2BC17B", color: "#2BC17B" }} onClick={() => this.deleteMajorClose()}>取消</Button>
                  </div>
                </div>
              </Modal>
            </Spin>
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    this.departmentPaging(1)
  }
  departmentPaging(page) {
    this.setState({
      ListLoading: true
    })
    return new Promise(resolve => {
      let limit = 10
      let newoffset = (page - 1) * limit
      service.request({
        url: "/department",
        method: "get",
        params: {
          limit: 10,
          offset: newoffset,
          name: this.state.Name
        }
      }).then(res => {
        if (res.code != 200) {
          message.error('数据加载失败，请重试');
          this.setState({
            ListLoading: false
          })
        } else {
          this.setState({ departmentData: res.data,departmentID:res.data[0].ID, DepartmentOffset: newoffset, DepartmenCount: res.count, DepartmentPages: page, ListLoading: false },()=>{
            this.majorPaging(1)
          })
          resolve()
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error, )
      })
    })
  }
  major(record){
    this.setState({
      departmentID:record.ID},()=>{this.majorPaging(1)})
  }
  majorPaging(page){
    this.setState({
      ListLoading: true
    })
    return new Promise(resolve => {
      let limit = 10
      let newoffset = (page - 1) * limit
      service.request({
        url: "/major",
        method: "get",
        params: {
          limit: 10,
          offset: newoffset,
          departmentID:this.state.departmentID,
        }
      }).then(res => {
        if (res.code != 200) {
          message.error('数据加载失败，请重试');
          this.setState({
            ListLoading: false
          })
        } else {
          this.setState({ MajorData: res.data, MajorOffset: newoffset, MajorCount: res.count, MajorPages: page, ListLoading: false })
          resolve()
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    })
  }
  onSearch(value) {
    this.setState({
      Name: value
    }, () => { this.departmentPaging(1) })

  }
  // 添加院系
  addDepartmentRef = (ref) => {
    this.AddDepartmentRef = ref
  }
  addDepartmentDrawer() {
    this.setState({
      AddDepartmentVisible: true,
    });
  };
  addDepartmentClose() {
    this.setState({
      AddDepartmentVisible: false,
    });
  };
  addDepartmentSave() {
    if (this.AddDepartmentRef.state.Name != "" && this.AddDepartmentRef.state.EnglishName != 0 && this.AddDepartmentRef.state.file != "" && this.AddDepartmentRef.state.TextAreaValue != "" ) {
      let formdata = new FormData
      formdata.set("Name", this.AddDepartmentRef.state.Name,)
      formdata.set("EnglishName", this.AddDepartmentRef.state.EnglishName,)
      formdata.set("Cover", this.AddDepartmentRef.state.file,)
      formdata.set("Note", this.AddDepartmentRef.state.TextAreaValue,)
      formdata.set("SchoolID", 1,)
      this.setState({
        addLoading: true,
      })
      service.request({
        url: "/department",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formdata,
      }).then(res => {
        if (res.code == 200) {
          this.departmentPaging(1)
          this.setState({
            addLoading: false,
          })
          this.addDepartmentClose()
          message.success('创建成功');
        } else {
          this.setState({
            addLoading: false,
          })
          if (res.code == 452) {
            message.error('创建失败,名字重复');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error("不能为空")
    }
  }
  // 修改院系
  editorDepartmentRef = (ref) => {
    this.EditorDepartmentRef = ref
  }
  editorDepartmentDrawer(record) {
    this.setState({
      DepartmentItem: record,
      EditorDepartmentVisible: true,
    })
  };
  editorDepartmentClose() {
    this.setState({
      EditorDepartmentVisible: false,
    });
  };
  editorDepartmentSave() {
    if (this.EditorDepartmentRef.state.Name != "" && this.EditorDepartmentRef.state.EnglishName != 0 && this.EditorDepartmentRef.state.file != "" && this.EditorDepartmentRef.state.TextAreaValue != "" ) {
      this.setState({
        EditorLoading: true,
      })
      service.request({
        url: "/department",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: this.EditorDepartmentRef.state.formdata,
      }).then(res => {
        if (res.code == 200) {
          this.departmentPaging(1)
          this.setState({
            EditorLoading: false,
          })
          this.editorDepartmentClose()
          message.success('添加成功');
        } else {
          this.setState({
            EditorLoading: false,
          })
          if (res.code == 452) {
            message.error('添加失败,名字重复');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error("不能为空")
    }
  }
  // 添加专业
  addMajorRef = (ref) => {
    this.AddMajorRef = ref
  }
  addMajorDrawer() {
    this.setState({
      addMajorVisible: true,
    });
  };
  addMajorClose() {
    this.setState({
      addMajorVisible: false,
    });
  };
  addMajorSave() {
    if (this.AddMajorRef.state.Name != "" && this.AddMajorRef.state.file != "" && this.AddMajorRef.state.Target != "" && this.AddMajorRef.state.Employment != ""&& this.AddMajorRef.state.DepartmentID != 0 ) {
      let formdata = new FormData
      formdata.set("Name", this.AddMajorRef.state.Name,)
      formdata.set("Cover", this.AddMajorRef.state.file,)
      formdata.set("Target", this.AddMajorRef.state.Target,)
      formdata.set("Employment", this.AddMajorRef.state.Employment,)
      formdata.set("DepartmentID", this.AddMajorRef.state.DepartmentID,)
      this.setState({
        addLoading: true,
      })
      service.request({
        url: "/major",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formdata,
      }).then(res => {
        if (res.code == 200) {
          this.departmentPaging(1)
          this.setState({
            addLoading: false,
          })
          this.addMajorClose()
          message.success('创建成功');
        } else {
          this.setState({
            addLoading: false,
          })
          if (res.code == 452) {
            message.error('创建失败,名字重复');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error("不能为空")
    }
  }
  
  //修改专业
  editorMajorRef = (ref) => {
    this.EditorMajorRef = ref
  }
  editorMajorDrawer(record) {
    this.setState({
      MajorItem: record,
      EditorMajorVisible: true,
    });
  };
  editorMajorClose() {
    this.setState({
      EditorMajorVisible: false,
    });
  };
  editorMajorSave() {
    if (this.EditorMajorRef.state.Name != "" && this.EditorMajorRef.state.file != ""&& this.EditorMajorRef.state.Target != ""&& this.EditorMajorRef.state.Employment != ""&& this.EditorMajorRef.state.DepartmentID != "") {
      this.setState({
        EditorLoading: true,
      })
      service.request({
        url: "/major",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: this.EditorMajorRef.state.formdata,
      }).then(res => {
        if (res.code == 200) {
          this.departmentPaging(1)
          this.setState({
            EditorLoading: false,
          })
          this.editorMajorClose()
          message.success('添加成功');
        } else {
          this.setState({
            EditorLoading: false,
          })
          if (res.code == 452) {
            message.error('添加失败,名字重复');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error("不能为空")
    }
  }

  // 删除院系
  deleteDepartmentModal(record) {
    this.setState({
      DeleteDepartmentVisible: true,
      departmentID: record.ID,
    });
  };
  hideModal() {
    this.setState({
      DeleteDepartmentVisible: false,
    });
  };
  determineDelete() {
    service.request({
      url: "/department",
      method: "delete",
      data: {
        ID: this.state.departmentID
      }
    }).then(res => {
      if (res.code == 200) {
        this.hideModal()
        this.departmentPaging(1)
        message.success('删除成功');
      } else {
        if(res.code==451){
          message.error('该院系下面有专业，无法删除');
          this.hideModal()
        }
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
  // 删除专业
  deleteMajorShow(record) {
    this.setState({
      DeleteMajorVisible: true,
      majorID: record.ID,
    });
  };
  deleteMajorClose() {
    this.setState({
      DeleteMajorVisible: false,
    });
  };
  DeleteMajor() {
    service.request({
      url: "/major",
      method: "delete",
      data: {
        ID: this.state.majorID
      }
    }).then(res => {
      if (res.code == 200) {
        this.deleteMajorClose()
        this.majorPaging(1)
        message.success('删除成功');
      } else {
        message.error('删除失败');
        this.deleteMajorClose()
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
}
