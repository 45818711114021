import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router,Route,Switch,Redirect} from 'react-router-dom'
import App from './App'
import { mainRoutes } from './routes'
import 'antd/dist/antd.css'


ReactDOM.render(
    <Router>
      {/* <Switch> */}
        <Route  render={routeProps=><App {...routeProps}/>}/>
        {mainRoutes.map(route=>{
          return <Route key={route.path} {...route}/>
        })}
      {/* </Switch> */}
    </Router>,
  document.getElementById('root')
);
