import React, { Component } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Switch, Button, Table, Input, Spin, Drawer, Space, Modal, message, } from 'antd';
import service from "../../request"
import moment from "moment";
//
import SignUpDetails from "../admissions/signups/signupDetails"
const { Search } = Input;
export default class SignUp extends Component {
    constructor(props) {
        super(props)
        //
        this.childEditorRef = null
        this.childaddRef = null
        this.state = {
            Data: [],
            pages: 1,//当前页
            isread: 0,
            count: 0,
            offset: 0,
            searchValue: "",
            IsTop: 0,
            tableItem: {},//当前行数据
            tableID: 0,//当前行ID
            header: 'http://49.4.2.15:9090/',
            ListLoading: false,
            EditorLoading: false,// 加载状态
            EditorVisible: false,
            addLoading: false,// 加载状态
            addVisible: false,
            DeleteLoading: false,// 加载状态
            DeleteVisible: false,
        }
    }
    render() {
        const columns = [
            { title: '名字', dataIndex: 'Name', key: 'Name' },
            { title: '年龄', dataIndex: 'Age', key: 'Age' },
            { title: '电话', dataIndex: 'Phone', key: 'Phone' },
            { title: '专业', dataIndex: 'MajorName', key: 'MajorName' },
            {
                title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
                    return <div>
                        <Button onClick={() => this.editorShowDrawer(record)} style={{ color: "#2BC17B", }} >查看</Button>
                        <Button onClick={() => this.deleteShowModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
                    </div>
                }
            },
        ];
        // moment(parseInt(时间戳)).format("YYYY-MM-DD HH:mm:ss"); 

        // const rowSelection = {
        //     onChange(selectedRowKeys, selectedRows) {
        //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //     },
        //     onSelect(record, selected, selectedRows) {
        //         console.log(record, selected, selectedRows);
        //     },
        //     onSelectAll(selected, selectedRows, changeRows) {
        //         console.log(selected, selectedRows, changeRows);
        //     },
        // };
        const paginationProps = {
            current: this.state.page, //当前页码
            pageSize: 10, // 每页数据条数
            showTotal: (total) => (
                <span>共{total}条</span>
            ),
            total: this.state.count, // 总条数
            onChange: page => handlePageChange(page), //改变页码的函数
            hideOnSinglePage: false,
            showSizeChanger: false,
        }
        const handlePageChange = (page) => {
            // console.log(page, "page", paginationProps.pageSize)
            this.setState({ pages: page })
            this.paging(page)
        }
        return (
            <div style={{ minWidth: "1200px" }}>
                {/* 头部 */}
                <div style={{ background: "#f5f9fa", width: "100%", height: "60px", padding: "10px", display: "flex", justifyContent: 'flex-start', flexDirection: 'row', }}>
                    <Button type="primary" style={{ height: "40px" }} onClick={() => this.allRead()}>全部</Button>
                    <Button style={{ marginLeft: "20px", height: "40px" }} type="primary" onClick={() => this.read()}>已读</Button>
                    <Button type="primary" style={{ marginLeft: "20px", height: "40px" }} onClick={() => this.unRead()}>未读</Button>
                </div>
                {/* 内容 */}
                <div style={{ marginTop: "20px", width: "100%", height: "100%", background: "#fff" }}>
                    <Spin spinning={this.state.ListLoading} tip="加载中......" size="large">
                        <Table columns={columns}
                            // rowSelection={rowSelection}
                            dataSource={this.state.Data}
                            className="table"
                            size={"small"}
                            // bordered={true}
                            pagination={paginationProps}
                        />
                    </Spin>
                    <Spin spinning={this.state.EditorLoading} tip="加载中......" size="large">
                        <Drawer
                            visible={this.state.EditorVisible}
                            title="详情"
                            width={500}
                            keyboard={true}//Esc关闭
                            onClose={() => this.onEditorClose()}
                            destroyOnClose={true}
                        >
                            <SignUpDetails ref={this.editorRef} tableItem={this.state.tableItem} ></SignUpDetails>
                            {/* 底部 */}
                            <div style={{ position: "absolute", left: "24px", bottom: "24px" }}>
                                <Space>
                                    {/* <Button onClick={() => this.editorSave()} type="primary">确定</Button> */}
                                    <Button onClick={() => this.onEditorClose()} type="primary">返回</Button>
                                </Space>
                            </div>
                        </Drawer>
                    </Spin>
                    <Spin spinning={this.state.DeleteLoading} tip="加载中......" size="large">
                        <Modal
                            destroyOnClose={true}
                            centered={true}
                            title={null}
                            footer={null}
                            modalRender={(modal) => (
                                modal
                            )}
                            // bodyStyle={{ padding: '0px' }}
                            visible={this.state.DeleteVisible}
                            onCancel={() => this.hideModal()}
                            closable={true}
                            width={400}
                        >
                            <div style={{ heighe: "205px", padding: "10px 20px" }}>
                                <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                                <div style={{ marginTop: "20px", color: "#666666" }}>确定删除词条数据？</div>
                                <div style={{ marginTop: "20px", textAlign: "center" }}>
                                    <Button type="primary" style={{ background: "#2BC17B", border: "1px solid #2BC17B", marginRight: "40px" }} onClick={() => this.determineDelete()}>确认</Button>
                                    <Button style={{ border: "1px solid #2BC17B", color: "#2BC17B" }} onClick={() => this.hideModal()}>取消</Button>
                                </div>
                            </div>
                        </Modal>
                    </Spin>
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.paging(1)
    }
    renderChecked(IsTop) {
        switch (IsTop) {
            case 1:
                return true;
            case 2:
                return false;
            default:
                return true
        }
    }
    paging(page) {
        this.setState({
            ListLoading: true
        })
        return new Promise(resolve => {
            let limit = 10
            let newoffset = (page - 1) * limit
            service.request({
                url: "/apply",
                method: "get",
                params: {
                    limit: 10,
                    offset: newoffset,
                    columnID: 1,
                    name: this.state.Name,
                    isRead: this.state.isread,
                }
            }).then(res => {
                if (res.code != 200) {
                    message.error('数据加载失败，请重试');
                    this.setState({
                        ListLoading: false
                    })
                } else {
                    this.setState({ Data: res.data, offset: newoffset, count: res.count, page: page, ListLoading: false })
                    resolve()
                }
            }).catch(function (error) {
                message.error('系统繁忙');
                console.log(error)
            })
        })
    }

    // 筛选
    allRead() {
        this.setState({
            isread: 0,
        },()=>{
            this.paging(1)
        })
    }
    read() {
        this.setState({
            isread: 1,
        },()=>{
            this.paging(1)
        })
    }
    unRead() {
        this.setState({
            isread: 2,
        },()=>{
            this.paging(1)
        })
    }
    // 修改
    editorRef = (ref) => {
        this.childEditorRef = ref
    }
    editorShowDrawer(record) {
        this.setState({
            tableItem: record,
            EditorVisible: true,
        });
    };
    onEditorClose() {
        this.setState({
            EditorVisible: false,
        });
    };
  
    
    // 删除显示窗口
    deleteShowModal(record) {
        this.setState({
            DeleteVisible: true,
            tableID: record.ID,
        });
    };
    hideModal() {
        this.setState({
            DeleteVisible: false,
        });
    };
    determineDelete() {
        service.request({
            url: "/apply",
            method: "delete",
            data: {
                ID: this.state.tableID
            }
        }).then(res => {
            if (res.code == 200) {
                this.hideModal()
                this.paging(1)
                message.success('删除成功');
            } else {
                message.error('删除失败');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })


    }

}
