import React from 'react';
import { Input, Button, Upload, message } from 'antd';

import { UploadOutlined } from '@ant-design/icons'


import service from '../../../request';
// import service from "./request"

class EditorPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID: 0,
            file: {},
            Name: "",
            PathName:"",
            Path:"",
            header: 'http://49.4.2.15:9090/',

            formdata: new FormData,
            fileList : []
        };
    }
    // 抽屉
    render() {

        return (
            <div>
                <div style={{ width: "500px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>名称：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "20px" }} value={this.state.Name} onChange={(e) => {
                        this.state.formdata.set("Name", e.target.value,)
                        this.setState({Name:e.target.value, })
                    }} placeholder="名称" />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "20px" }}>图片：</label>
                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}>
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </div>
            </div>

        );
    }
    componentDidMount() {
        this.setState({
            ID: this.props.tableItem.ID,
            Name: this.props.tableItem.Name,
            Path: this.props.tableItem.Path,
        }, () => {
            this.state.formdata.set("ID", this.props.tableItem.ID,)
           var Paths= this.state.Path.split("/")
           var Pathslength=Paths.length-1
           var PathsItem=Paths[Pathslength]
            this.setState({
                PathName:PathsItem,
                fileList:[{
                    uid: '-1',
                    name:PathsItem,
                    status: 'done',
                    url:this.state.header+this.state.Path,
                    thumbUrl: this.state.header+this.state.Path,
                  }]
            })
        })
    }
    Upload(f) {
        this.state.formdata.set("File", f.file,)
        this.setState({file:f.file,fileList:f.fileList})
    }
    beforeUpload() {
        return false
    }
}

export default EditorPopup;