import React from 'react';
import { Input, Button, Upload, DatePicker, ConfigProvider, Switch, Select, message } from 'antd';
import service from '../../../request';
// import service from "./request"
const { TextArea } = Input;
class SignUpDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID: 0,
            Data: {},


        };
    }
    // 抽屉
    render() {
        return (
            <div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginLeft: "20px" }}>姓名：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "32px" }} value={this.state.Data.Name} placeholder="标题" />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginLeft: "20px" }}>年龄：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "32px" }} value={this.state.Data.Age} placeholder="标题" />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginLeft: "20px" }}>电话：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "32px" }} value={this.state.Data.Phone} placeholder="标题" />
                </div >
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginLeft: "20px" }}>专业：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "32px" }} value={this.state.Data.MajorName} placeholder="标题" />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginLeft: "20px" }}>地址：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "32px" }} value={this.state.Data.Address} placeholder="标题" />
                </div>
                <div style={{ width: "416px", marginBottom: "20px", display: "flex" }}>
                    <div style={{ width: "100px", color: "#000000", fontSize: "16px", marginLeft: "20px" }}>备注：</div>
                    <TextArea
                    style={{marginLeft:"6px"}}
                        value={this.state.Data.Note}
                        placeholder="请输入"
                        autoSize={{
                            minRows: 3,
                            // maxRows: 5,
                        }} />
                </div>
            </div>

        );
    }
    componentDidMount() {
        this.getCategory()
    }
    getCategory() {
        service.request({
            url: "/apply/queryByID",
            method: "get",
            params: {
                ID: this.props.tableItem.ID
            }
        }).then(res => {
            if (res.code == 200) {
                this.setState({
                    Data: res.data
                })
            } else {
                message.error('数据出错，请重试');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        }).finally(() => {

        })
    }

    renderChecked(IsTop) {
        switch (IsTop) {
            case 1:
                return true;
            case 2:
                return false;
            default:
                return true
        }
    }

}

export default SignUpDetails;