import React from 'react';
import { Input, Button, Upload,  message } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import service from '../../../request';
// import service from "./request"
const { TextArea } = Input;


class EditorDepartment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID: 0,
            file: {},
            Title: "",
            Time: 0,
            CoverName:"",
            Cover:"",
            header: 'http://49.4.2.15:9090/',
            CategoryName: "",
            secondary: [],
            schoolList: [],
            SchoolName: "",
            SchoolID: 0,
            SchoolID: 0,
            IsTop: 1,
            formdata: new FormData,
            fileList : []
        };
    }
    // 抽屉
    render() {

        return (
            <div>
                 <div style={{ width: "500px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>院系名称：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "30px" }} value={this.state.Name} onChange={(e) => {
                        this.state.formdata.set("Name", e.target.value,)
                        this.setState({ Name: e.target.value }, () => {
                        })
                    }} placeholder="院系名称" />
                </div>
                <div style={{ width: "500px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>英文名称：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "30px" }} value={this.state.EnglishName} onChange={(e) => {
                         this.state.formdata.set("EnglishName", e.target.value,)
                        this.setState({ EnglishName: e.target.value }, () => {
                        })
                    }} placeholder="标题" />
                </div>
               
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginLeft: "32px",marginRight: "30px" }}>封面：</label>
                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}>
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </div>
                <div style={{ width: "600px", marginBottom: "20px",display:"flex" }}>
                    <div style={{ width:"100px",color: "#000000", fontSize: "16px",marginRight:"25px" }}>院系备注：</div>
                    <TextArea
                        value={this.state.TextAreaValue}
                        onChange={(e) => { 
                            this.state.formdata.set("Note", e.target.value,)
                            this.setState({ TextAreaValue: e.target.value })}}
                        placeholder="请输入"
                        autoSize={{
                            minRows: 3,
                            // maxRows: 5,
                        }}
                    />
                </div>
            </div>

        );
    }
    componentDidMount() {
        this.setState({
            ID: this.props.tableItem.ID,
            Name:this.props.tableItem.Name,
            EnglishName:this.props.tableItem.EnglishName,
            Cover: this.props.tableItem.Cover,
            TextAreaValue:this.props.tableItem.Note,
        }, () => {
           var covers= this.state.Cover.split("/")
           var coverslength=covers.length-1
           var coversItem=covers[coverslength]
            this.setState({
                CoverName:coversItem,
                fileList:[{
                    uid: '-1',
                    name:coversItem,
                    status: 'done',
                    url:this.state.header+this.state.Cover,
                    thumbUrl: this.state.header+this.state.Cover,
                  }]
            })
            this.state.formdata.set("ID", this.state.ID,)
        })
    }
   
    Upload(f) {
        this.state.formdata.set("Cover", f.file,)
        this.setState({file:f.file,fileList:f.fileList})
    }
    beforeUpload() {
        return false
    }

}

export default EditorDepartment;