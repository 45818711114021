import React from 'react';
import { Input, Button, Upload,message } from 'antd';
import { UploadOutlined } from '@ant-design/icons'


import service from '../../../request';
// import service from "./request"

class AddPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            Name: "",
            // formdata : new FormData,
        };
    }
    // 抽屉
    render() {
        return (
            <div>
                <div style={{ width: "300px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>名称：</label>
                    <Input style={{ height: "35px", width: "200px", padding: "5px", marginLeft: "20px" }} value={this.state.Name} onChange={(e) => {
                        this.setState({ Name: e.target.value })
                    }} placeholder="名称" />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "20px" }}>图片：</label>
                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }}>
                        <Button icon={<UploadOutlined />}>上传图片</Button>
                    </Upload>
                </div>
            </div>

        );
    }
    componentDidMount() {
    }

    Upload(f) {
        this.setState({
            file: f.file
        })
        
    }
    beforeUpload() {
        return false
    }
}

export default AddPopup;