import React from 'react';
import { Input, Button, Upload,  Select, message } from 'antd';

import { UploadOutlined } from '@ant-design/icons'


import service from '../../../request';
// import service from "./request"

const { TextArea } = Input;
const { Option } = Select;
class EditorMajor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID: 0,
            file: {},
            Name: "",
            Target: "",
            Employment:"",
            CoverName:"",
            Cover:"",
            header: 'http://49.4.2.15:9090/',
            departmentData: [],
            departmentName:"",
            DepartmentID:0,
            formdata: new FormData,
            fileList : []
        };
    }
    // 抽屉
    render() {

        return (
            <div>
                <div style={{ width: "500px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>专业名称：</label>
                    <Input style={{ height: "35px", width: "300px", padding: "5px", marginLeft: "20px" }} value={this.state.Name} onChange={(e) => {
                        this.state.formdata.set("Name", e.target.value,)
                        this.setState({Name:e.target.value, })
                    }} placeholder="专业名称" />
                </div>
                <div style={{ width: "600px", marginBottom: "20px",display:"flex" }}>
                    <div style={{ width:"100px",color: "#000000", fontSize: "16px",marginRight:"16px" }}>专业目标：</div>
                    <TextArea
                        value={this.state.Target}
                        onChange={(e) => { 
                            this.state.formdata.set("Target", e.target.value,)
                            this.setState({ Target: e.target.value })}}
                        placeholder="请输入"
                        autoSize={{
                            minRows: 3,
                            // maxRows: 5,
                        }}
                    />
                </div>
                <div style={{ width: "600px", marginBottom: "20px",display:"flex" }}>
                    <div style={{ width:"100px",color: "#000000", fontSize: "16px",marginRight:"16px" }}>就业方向：</div>
                    <TextArea
                        value={this.state.Employment}
                        onChange={(e) => { 
                            this.state.formdata.set("Employment", e.target.value,)
                            this.setState({ Employment: e.target.value })}}
                        placeholder="请输入"
                        autoSize={{
                            minRows: 3,
                            // maxRows: 5,
                        }}
                    />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "20px" }}>专业封面：</label>
                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}>
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </div>
               
               
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "20px" }}>选择院系：</label>
                    <Select
                        showSearch
                        style={{ width: "300px", }}
                        placeholder="选择院系"
                        optionFilterProp="children"
                        defaultActiveFirstOption={true}
                        dropdownMatchSelectWidth={true}
                        value={this.state.departmentName}
                        onChange={(e) => {
                            this.state.formdata.set("DepartmentID", this.state.departmentData[e].ID,)
                            this.setState({
                                DepartmentID: this.state.departmentData[e].ID,
                                departmentName: this.state.departmentData[e].Name,
                            })
                        }}
                    >
                        {this.state.departmentData.map((item, index) => (
                            <Option key={item.ID} value={index} >
                                {item.Name}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>

        );
    }
    componentDidMount() {
        this.parsing()
        this.setState({
            departmentData:this.props.departmentData,
            ID: this.props.tableItem.ID,
            DepartmentID: this.props.tableItem.DepartmentID,
            Name: this.props.tableItem.Name,
            Target: this.props.tableItem.Target,
            Employment: this.props.tableItem.Employment,
            Cover: this.props.tableItem.Cover,
        }, () => {
           var covers= this.state.Cover.split("/")
           var coverslength=covers.length-1
           var coversItem=covers[coverslength]
            this.setState({
                CoverName:coversItem,
                fileList:[{
                    uid: '-1',
                    name:coversItem,
                    status: 'done',
                    url:this.state.header+this.state.Cover,
                    thumbUrl: this.state.header+this.state.Cover,
                  }]
            })
            this.state.formdata.set("ID", this.state.ID,)
        })
    }
    parsing() {
        const str = this.props.departmentData.find((ele) => { return ele.ID ==this.props.tableItem.DepartmentID })
        if (str == undefined) {
            return ""
        }
        this.setState({
            departmentName: str.Name
        })
    }
    Upload(f) {
        this.state.formdata.set("Cover", f.file,)
        this.setState({file:f.file,fileList:f.fileList})
    }
    beforeUpload() {
        return false
    }
    onChangeTime(date, dateString) {
        this.state.formdata.set("Time",  new Date(date).getTime() * 1000000,)
        this.setState({Time:new Date(date).getTime() * 1000000,})
    }
}

export default EditorMajor;