import React from 'react';
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { Input, Button, Upload, DatePicker, ConfigProvider, Switch, Select, message } from 'antd';
import moment from 'moment';
// moment.locale('zh-cn');
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { UploadOutlined } from '@ant-design/icons'
import cookie from "react-cookies";

import service from '../../../request';
// import service from "./request"

const toolbarConfig = { }
const editorConfig = {
    placeholder: '请输入内容...',
    MENU_CONF: {}
}

editorConfig.MENU_CONF['uploadImage'] = {
    // server: 'http://10.18.1.99:9090/admin/upload',
    server: 'http://49.4.2.15:9090/admin/upload',
    fieldName: 'file',
    // 单个文件的最大体积限制，默认为 2M
    maxFileSize: 10 * 1024 * 1024, // 1M
    // 自定义增加 http  header
    headers: {
        token: cookie.load("token"),
    },
}

editorConfig.MENU_CONF['uploadVideo'] = {
    // server: 'http://10.18.1.99:9090/admin/upload',
    server: 'http://49.4.2.15:9090/admin/upload',
    fieldName: 'file',
    // 单个文件的最大体积限制，默认为 10M
    maxFileSize: 100 * 1024 * 1024, // 5M
    // 自定义增加 http  header
    headers: {
        token: cookie.load("token"),
    },
    // 超时时间，默认为 30 秒
    timeout: 30 * 1000, // 15 秒
}

const { Option } = Select;
class AddPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            Title: "",
            Time: 0,
            secondary: [],
            schoolList: [],
            schoolID: 0,
            categoryID: 0,
            IsTop: 1,
            // formdata : new FormData,
            editorState:"",
        };
    }
    // 抽屉
    render() {
        return (
            <div>
                <div style={{ width: "300px", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px" }}>标题：</label>
                    <Input style={{ height: "35px", width: "200px", padding: "5px", marginLeft: "40px" }} value={this.state.Title} onChange={(e) => {

                        this.setState({ Title: e.target.value })
                    }} placeholder="标题" />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "40px" }}>封面：</label>
                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }}>
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "40px" }}>日期：</label>
                    <ConfigProvider locale={zh_CN}>
                        <DatePicker onChange={(date, dateString) => this.onChangeTime(date, dateString)} />
                    </ConfigProvider>

                </div >
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "40px" }}>置顶：</label>
                    <Switch checkedChildren="开启" unCheckedChildren="关闭" onChange={() => this.changeSwitch()} checked={this.renderChecked(this.state.IsTop)} />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "5px" }}>二级菜单：</label>
                    <Select
                        showSearch
                        style={{ width: "300px", }}
                        placeholder="二级分类"
                        optionFilterProp="children"
                        defaultActiveFirstOption={true}
                        dropdownMatchSelectWidth={true}
                        onChange={(e) => {
                            this.setState({
                                categoryID: this.state.secondary[e].ID,

                            })
                        }}
                    >
                        {this.state.secondary.map((item, index) => (
                            <Option key={item.ID} value={index} >
                                {item.Name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "16px", marginRight: "5px" }}>选择学校：</label>
                    <Select
                        showSearch
                        style={{ width: "300px", }}
                        placeholder="选择学校"
                        optionFilterProp="children"
                        defaultActiveFirstOption={true}
                        dropdownMatchSelectWidth={true}
                        onChange={(e) => {
                            this.setState({
                                schoolID: this.state.schoolList[e].ID,
                            })
                        }}
                    >
                        {this.state.schoolList.map((item, index) => (
                            <Option key={item.ID} value={index} >
                                {item.Name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <div style={{ color: "#000000", fontSize: "16px", marginBottom: "10px", }}>内容：</div>
                   
                    <div style={{ border: '1px solid #ccc', zIndex: 100, marginTop: '15px' }}>
                        <Toolbar
                            editor={this.state.editor}
                            defaultConfig={toolbarConfig}
                            mode="default"
                            style={{ borderBottom: '1px solid #ccc' }}
                        />
                        <Editor
                            defaultConfig={editorConfig}
                            value={this.state.editorState}
                            onCreated={(editor)=>this.setEditor(editor)}
                            onChange={editor => this.setHtml(editor.getHtml())}
                            mode="default"
                            style={{ height: '500px' }}
                        />
                    </div>
                </div>
            </div>

        );
    }
    //
    setEditor(e){
        this.setState({editor:e})
    }
    setHtml(e){
        this.setState({editorState:e})
    }
    //
    componentDidMount() {
        this.getCategory()
        this.getSchool()
    }
    getCategory() {
        service.request({
            url: "/category",
            method: "get",
            params: {
                columnID: 1,
            }
        }).then(res => {

            if (res.code == 200) {
                this.setState({
                    secondary: res.data
                })
            } else {
                message.error('系统繁忙');
            }
        }).catch(function (error) {

            console.log(error)
        }).finally(() => {

        })
    }
    getSchool() {
        service.request({
            url: "/school",
            method: "get",
            params: {
            }
        }).then(res => {
            if (res.code == 200) {
                this.setState({
                    schoolList: res.data
                })
            } else {
                message.error('系统繁忙');
            }
        }).catch(function (error) {

            console.log(error)
        }).finally(() => {

        })
    }
    Upload(f) {
        this.setState({
            file: f.file
        })
    }
    beforeUpload() {
        return false
    }
    onChangeTime(date, dateString) {
        this.setState({
            Time: new Date(date).getTime() * 1000000
        })
    }
    renderChecked(IsTop) {
        switch (IsTop) {
            case 1:
                return true;
            case 2:
                return false;
            default:
                return true
        }
    }
    changeSwitch() {
        if (this.state.IsTop == 1) {
            this.setState({
                IsTop: 2
            })
        } else {
            this.setState({
                IsTop: 1
            })
        }
    }
}
export default AddPopup;
