import { Route, Redirect } from 'react-router-dom'

import Login from "../pages/Login";
import { ShopOutlined } from '@ant-design/icons';
import HomePage from "../pages/homepage/homepage";
import SignUp from "../pages/admissions/signup";
import SchoolProfile from "../pages/schoolprofile/schoolprofile";
import News from "../pages/news/news";
import News2 from "../pages/news2/news";
import News3 from "../pages/news3/news";
import News4 from "../pages/news4/news";
import News5 from "../pages/news5/news";
import Swipper from '../pages/homepage/swipper';
import Link from '../pages/homepage/link';

export const mainRoutes = [{
    path: '/login',
    component: Login
}]

export const adminRoutes = [{
    path: '/admin/home',
    isShow: true,
    exact: true,
    title: '首页管理',
    icon: <ShopOutlined />,
    children: [
        {
            path: '/admin/home/homepage',
            isShow: true,
            exact: true,
            title: '基本设置',
            component: HomePage,
        },
        {
            path: '/admin/home/swipper',
            isShow: true,
            exact: true,
            title: '轮播图管理',
            component: Swipper,
        },
        {
            path: '/admin/home/link',
            isShow: true,
            exact: true,
            title: '友情链接',
            component: Link,
        },
    ]
}, 
, {
    path: '/admin/admissions',
    isShow: true,
    exact: true,
    title: '招生报名',
    icon: <ShopOutlined />,
    component: SignUp,
    // children: [
    //     {
    //         path: '/admin/admissions/signups',
    //         isShow: true,
    //         exact: true,
    //         title: '招生报名',
    //     },
    // ]
},
 {
    path: '/admin/schoolprofile',
    isShow: true,
    exact: true,
    title: '学校概况管理',
    icon: <ShopOutlined />,
    children: [
        {
            path: '/admin/schoolprofile/home',
            isShow: true,
            exact: true,
            title: '专业管理',
            component: SchoolProfile,
        },
    ]
},
 {
    path: '/admin/news',
    isShow: true,
    exact: true,
    title: '新闻管理',
    icon: <ShopOutlined />,
    children: [
        {
            path: '/admin/news/swipper',
            isShow: true,
            exact: true,
            title: '花都新闻',
            component: News,
        },
        {
            path: '/admin/news/swipper2',
            isShow: true,
            exact: true,
            title: '升学资讯',
            component: News2,
        },
        {
            path: '/admin/news/swipper3',
            isShow: true,
            exact: true,
            title: '学生服务',
            component: News3,
        },
        {
            path: '/admin/news/swipper4',
            isShow: true,
            exact: true,
            title: '教学科研',
            component: News4,
        },
        {
            path: '/admin/news/swipper5',
            isShow: true,
            exact: true,
            title: '团委动态',
            component: News5,
        },
    ]
}]


export const renderRoutesMain = (Routes) => {
    return Routes.map((route, index) => {
        return (
            <div key={index}>
                < Route
                    path={route.path}
                    isShow={route.isShow}
                    exact={route.exact}
                    render={props => {
                        return <route.component {...props} />
                    }}
                />
                {route.children && renderRoutesMain(route.children)}
            </div>
        )
    })
}
