import axios from "axios";
import cookie from "react-cookies";


// 创建实例
const service = axios.create({
  baseURL:'http://49.4.2.15:9090/admin',
  // baseURL:'http://10.18.1.99:9090/admin',
})



// 请求拦截
service.interceptors.request.use(
  function(config){
  config.headers.token = cookie.load("token")
   return config;
  },function(error){
    return Promise.reject(error);
  }
)

service.interceptors.response.use(
  function (response) {
    // console.log("响应成功：");
    // console.log(response);
    // 响应数据处理
    return response.data;
  }, function (error) {
    // console.log("响应错误：");
    // console.log(error);
    // 响应错误处理
    return Promise.reject(error);
  }
);
 
 export default service;
