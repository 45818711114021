import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Checkbox, Card, message, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import cookie from "react-cookies";
import { setToken } from '../Utlis/auth';
import axios from "axios";
import './login.css'
import logo from '../../public/logo.png'

function Login(props) {
  const onFinish = (values) => {
    // service.request({
    //   method: 'POST',
    //   url: '/login',
    //   data: {
    //     username: values.username,
    //     password: values.password,
    //   }
    // }).then(res => {
    //   if (res.code === 200) {
    //     setToken(1);
    //     props.history.push('/admin/home/homepage')
    //   } else {
    //     message.info("用户名或密码错误！")
    //   }
    // })
    // console.log(values)
    axios.post('http://49.4.2.15:9090/login', {
      Username: values.username,
      Password: values.password,
    })
      .then(res => {
        if (res.data.code == 200) {
          let inFifteenMinutes = new Date(new Date().getTime() + 10 * 3600 * 1000);
          cookie.save("token", res.data.data.Token, { expires: inFifteenMinutes })
          props.history.push('/admin/home/homepage')
        }
        else {
          if (res.data.code == 411) {
            message.error('账号或密码错误')
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };


  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  return (
    <div style={{ background: '#282c34', position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <Card className='loginform' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img src={logo} alt='' />
        <h1 style={{ color: '#fff', fontWeight: 'bold', fontSize: '25px', margin: '10px 0' }}>花都形象艺术学校管理后台</h1>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: '请输入您的用户名!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入您的密码!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="密码"
            />
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "300px" }} type="primary" htmlType="submit" className="login-form-button">
              登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default Login
